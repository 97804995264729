import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroSlider = _resolveComponent("HeroSlider")!
  const _component_ProductMenu = _resolveComponent("ProductMenu")!
  const _component_ApplicationInfo = _resolveComponent("ApplicationInfo")!
  const _component_TechnologyInfo = _resolveComponent("TechnologyInfo")!
  const _component_ProvideService = _resolveComponent("ProvideService")!
  const _component_NewsInfo = _resolveComponent("NewsInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeroSlider, {
      bgImage: _ctx.homePageHero.backgroundImageUrl,
      title: _ctx.homePageHero.pageTitle,
      subtitle: _ctx.homePageHero.pageSubtitle,
      description: _ctx.homePageHero.pageDescription
    }, null, 8, ["bgImage", "title", "subtitle", "description"]),
    _createVNode(_component_ProductMenu),
    _createVNode(_component_ApplicationInfo),
    _createVNode(_component_TechnologyInfo),
    _createVNode(_component_ProvideService),
    _createVNode(_component_NewsInfo)
  ]))
}
import router from "./router";
import store from "./store";

import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";

import "./assets/css/basic.css";
import "./assets/css/global.less";
import "./assets/css/media.css";
import "./assets/css/fonts.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// 引入 Swiper 和相关样式
import "swiper/less";

// 创建 Vue 应用实例
const app = createApp(App);

// 挂载 ElementPlus
app.use(store).use(router).use(ElementPlus);

// 遍历 ElementPlusIconsVue 中的所有图标并注册为全局组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 挂载应用
app.mount("#app");

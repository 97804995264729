<template>
  <div class="product-menu-box">
    <h2 class="header-line">选择您所需的商用产品</h2>
    <div class="product-menu-swiper">
      <swiper
        :slidesPerView="4"
        :pagination="{ type: 'progressbar', clickable: true }"
        :scrollbar="{
          draggable: true,
          el: '.product-menu-scrollbar',
          hide: false,
        }"
        :space-between="25"
        :modules="modules"
        @mouseenter="enter"
        @mouseleave="leave"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide
          v-for="(item, index) in swiperImgArray"
          :key="index"
          class="pr"
        >
          <div class="product-menu-item" @click="jump(item.id)">
            <img :src="item.imgSrc" alt="" style="width: 110px; height: 81px" />
            <h2>
              {{ item.name }}
            </h2>
            <p>
              {{ item.intro }}
            </p>
            查看详情 >
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRaw, defineProps } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/less/navigation";
import "swiper/less/pagination";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import router from "@/router";
interface B {
  img: string;
}
let swiperImgArray = [
  {
    id: 1,
    name: "HP Latex 375 打印机",
    imgSrc: require("@/assets/img/product-375.png"),
    intro:
      "出纸装帧处理:卷筒进纸、收纸轴、自动切纸器（适用于乙烯膜、纸质介质、背光聚酯膜）",
  },
  {
    id: 2,
    name: "HP Latex 800 打印机",
    intro:
      "出纸装帧处理:卷筒进纸、收纸轴、擦除器滚筒[3]、介质节约程序、自动水平切纸器（适用于乙烯膜、横幅和画布[4]、纸质介质和胶片）",
    imgSrc: require("@/assets/img/product-800.png"),
  },
  {
    id: 3,
    name: "HP Latex 800W 打印机",
    intro:
      "出纸装帧处理:卷筒进纸、收纸轴、擦除器滚筒[3]、介质节约程序、自动水平切纸器（适用于乙烯膜、横幅和画布[4]、纸质介质和胶片）",
    imgSrc: require("@/assets/img/product-800w.png"),
  },
  {
    id: 4,
    name: "HP Latex R2000 打印机",
    intro:
      "出纸装帧处理:卷筒进纸、收纸轴、擦除器滚筒[3]、介质节约程序、自动水平切纸器（适用于乙烯膜、横幅和画布[4]、纸质介质和胶片）",
    imgSrc: require("@/assets/img/product-r2000.png"),
  },
];
let modules = [Autoplay, Navigation, Pagination, Scrollbar, A11y];
//定义swiperNew,目的获取非响应式swiper
let swiperNew: any;

//鼠标移入
const enter = () => {
  swiperNew.autoplay.stop();
};
//鼠标移出
const leave = () => {
  swiperNew.autoplay.start();
};
const onSwiper = (swiper: any) => {
  // console.log(swiper);

  swiperNew = toRaw(swiper); //拿到swiper对象再转换为非响应式
};
const onSlideChange = () => {
  // console.log("slide change");
};

const jump = (id: string) => {
  console.log("id", id);
  router.push({ path: "product", query: { id: id } });
};
</script>
<style lang="less" scoped>
.product-menu-box {
  background-color: #fff;
  margin-bottom: 60px;
  .header-line {
    font-size: 33px;
    padding: 4.1667vw 0 2.6042vw;
    text-align: center;
    font-weight: bold;
  }

  .product-menu-swiper {
    width: 100%;
    height: 400px;

    .pr {
      width: 355.5px;
      background: #fff;
      min-height: 100%;
      height: auto;
      min-height: 100%;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      transition: transform 0.5s ease, opacity 0.5s ease;

      .product-menu-item {
        border-top: 0.1px solid #ccc;
        padding: 2.6041vw 1.8229vw 1.5625vw;
        text-align: center;
        display: block;
        position: relative;
        overflow: hidden;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        img {
          transition: transform 0.3s ease, filter 0.3s ease;
        }

        h2,
        p {
          transition: color 0.3s ease;
        }
        h2 {
          margin-block-start: 0;
          margin-block-end: 0;
          font-size: 1.3517vw;
          padding: 0.9375vw 0;
          position: relative;
          font-weight: bold;
          color: black;
        }

        h2::before {
          content: "";
          width: 44px;
          height: 2px;
          background: #4294d1;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        p {
          color: #5a6168;
          line-height: 1.65;
          font-size: 1.0417vw;
          padding-bottom: 1.5625vw;
          text-align: left;
        }
      }
    }
    .pr:hover {
      background-color: #00a2e9; /* 设置背景颜色 */
      color: white; /* 设置所有字体为白色 */
      cursor: pointer;
      transform: translateY(-5px); /* 鼠标悬停时上移 */
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */

      .product-menu-item img {
        transform: scale(1.1); /* 鼠标悬停时图片放大 */
        filter: brightness(1.2); /* 增加图片亮度 */
      }

      h2::before {
        width: 100%; /* 鼠标悬停时横线宽度变宽 */
        transition: width 0.3s ease;
      }
      h2,
      p {
        color: white; /* 如果需要，确保子元素的字体颜色也变为白色 */
      }
    }
    // .pr:hover img {
    //   filter: grayscale(100%) brightness(0) invert(1);
    // }

    .pr:not(:last-child) {
      margin-right: 0.6rem;
    }
  }
  :deep(.swiper-pagination-progressbar) {
    height: 10px;
    width: 75%;
    top: 96%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    position: relative;
    text-align: center;
    margin-top: 30px;
  }

  :deep(.swiper-pagination-progressbar-fill) {
    border-radius: 10px;
    background-color: #00a2e9;
  }

  :deep(.select-product .swiper-container .swiper-slide) {
    margin-right: 25px !important;
  }
  :deep(.swiper-pagination) .swiper-pagination-progressbar {
    background-color: #e3e3e3 !important;
    border: 1px solid #d7d7d7;
  }
}
</style>

import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/product",
    name: "product",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/ProductDetails.vue"),
  },
  {
    path: "/ink",
    name: "ink",
    component: () =>
      import(/* webpackChunkName: "ink" */ "../views/InkView.vue"),
  },
  {
    path: "/application",
    name: "application",
    component: () =>
      import(
        /* webpackChunkName: "application" */ "../views/ApplicationIndustry.vue"
      ),
  },
  {
    path: "/curve-download",
    name: "curve-download",
    component: () =>
      import(
        /* webpackChunkName: "application" */ "../views/CurveDownload.vue"
      ),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "application" */ "../views/ContactView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;

<template>
  <div class="news-info-box">
    <h2>工作流程解决方案和服务</h2>
    <p class="title-desc">
      惠普已经与业内出色的提供商开展合作，几乎可为各种应用提供端到端工作流程解决方案的产品组合。您可以依赖我们的团队为您成功之路的保驾护航。
    </p>
    <ul class="news-info-list">
      <li
        v-for="item in list"
        :key="item.id"
        class="news-info-item"
        @click="jump(item)"
      >
        <img :src="item.imgSrc" alt="" class="news-info-img" />
        <h2 class="news-info-title">{{ item.title }}</h2>
        <span>了解更多 &gt;</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import router from "@/router";
let list = [
  {
    id: 1,
    title: "HP PrintOS",
    imgSrc: require("@/assets/img/case-printos.png"),
  },
  {
    id: 2,
    title: "合作伙伴解决方案",
    imgSrc: require("@/assets/img/case-cooperation.png"),
  },
  {
    id: 3,
    title: "应用",
    imgSrc: require("@/assets/img/case-application.png"),
  },
  {
    id: 4,
    title: "墨水和介质",
    imgSrc: require("@/assets/img/case-ink.png"),
  },
];

const jump = (item) => {
  console.log(item);
  if (item.id == 4) router.push({ path: "ink" });
  else router.push({ path: "contact" });
};
</script>

<style lang="less" scoped>
.news-info-box {
  background-color: #f8f8f8;
  padding: 0 9.5833vw 100px 9.5833vw;
  h2 {
    font-size: 33px;
    padding: 6vw 0.4042vw 1vw 0.4vw;
    text-align: center;
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: 0;
    color: black;
  }
  .title-desc {
    color: #6e6e73;
    margin-bottom: 60px;
  }
  .news-info-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -1.0417vw;
    margin-right: -1.0417vw;
    margin-bottom: -1.0417vw;
    .news-info-item {
      cursor: pointer;
      border-radius: 20px;
      width: calc(33.333% - 2.0834vw);
      margin: 1.0417vw 1.0417vw;
      padding-bottom: 25px;
      text-align: left;
      overflow: hidden; /* 确保放大效果不会超出容器 */
      position: relative; /* 为子元素设置定位上下文 */
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑的动画过渡效果 */

      &:hover {
        transform: translateY(-5px) scale(1.05); /* 鼠标悬停时上移并放大 */
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
        z-index: 1; /* 确保悬停的元素在视觉上位于其他元素之上 */
      }

      .news-info-img {
        width: 100%;
        height: 256px;
      }
      .news-info-title {
        font-size: 1.3542vw;
        font-weight: bold;
        line-height: 1.3;
        padding: 1.8229vw 1.0417vw;
        color: black;
        text-align: left;
      }

      span {
        font-size: 1.0417vw;
        color: #0586ef;
        text-align: left;
        padding: 0 1.0417vw;
      }
    }
  }
  .look-more-solution {
    padding: 1.9792vw 0 2.6042vw;
    text-align: center;
    span {
      font-size: 1.0417vw;
      color: #0586ef;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="home">
    <HeroSlider
      :bgImage="homePageHero.backgroundImageUrl"
      :title="homePageHero.pageTitle"
      :subtitle="homePageHero.pageSubtitle"
      :description="homePageHero.pageDescription"
    ></HeroSlider>
    <ProductMenu></ProductMenu>
    <ApplicationInfo></ApplicationInfo>
    <TechnologyInfo></TechnologyInfo>
    <ProvideService></ProvideService>
    <NewsInfo></NewsInfo>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HeroSlider from "@/components/home/HeroSlider.vue";
import ProductMenu from "@/components/home/ProductMenu.vue";
import ProvideService from "@/components/home/ProvideService.vue";
import NewsInfo from "@/components/home/NewsInfo.vue";
import TechnologyInfo from "@/components/home/TechnologyInfo.vue";
import { ref } from "vue";
import ApplicationInfo from "@/components/home/ApplicationInfo.vue";
@Options({
  components: {
    HeroSlider,
    ProductMenu,
    ProvideService,
    NewsInfo,
    TechnologyInfo,
    ApplicationInfo,
  },
  // 我可以在这里用类似之前vue2 options的方式
  // data(){ return {}} methods(){}这样
})
export default class HomeView extends Vue {
  homePageHero = ref({
    backgroundImageUrl: require("@/assets/img/home-hero.png"),
    pageTitle: "HP Latex 打印机", // 确保属性名称与模板中使用的一致
    pageSubtitle: "影响：业务、客户、社区",
    pageDescription:
      "当客户面临特有的挑战并渴望抓住创新机遇，您希望帮助他们获得成功。HP Latex技术就是这样一款强大的“武器”。打印机与之搭配使用后，可在几乎任何承印物上进行打印，输出稳定如一的高品质印件，同时出众的效率还有助于快速交付项目。",
  });
}
</script>

<template>
  <div class="application-introduce-box">
    <div class="transition-application-img"></div>
    <div class="headline">
      <h2>提升业务承接能力</h2>
      <p>借助 HP Latex，您可以通过提供一系列新应用产品来增加收入。</p>
    </div>
    <div class="application-introduce-content">
      <div class="text-module">
        <h2 style="padding: 0 6%">革新柔性标牌和展板图形行业</h2>
        <p style="padding: 0 6%">
          您今天不仅可以产生传统标牌，还将发掘一系列新应用带来的收入来源。HP
          Latex
          打印机输出的印件一经印出，立刻干燥，实现当日交付，在降低生产成本的同时践行端到端可持续发展理念。
        </p>
        <div style="padding: 0 6%">
          <el-button color="#333" size="large" class="button" @click="jump"
            >了解详情</el-button
          >
        </div>
      </div>
      <img src="@/assets/img/application-1.png" alt="" />
    </div>

    <div class="application-introduce-content">
      <img src="@/assets/img/application-2.png" alt="" />
      <div class="text-module">
        <h2 style="padding: 0 6%">装饰应用</h2>
        <p style="padding: 0 6%">
          装饰品打印市场正迎来飞速发展，众多企业都希望从中获得机会。数字打印具备客户所需的灵活性和可定制性，可以助您抢占这一高利润市场。
        </p>
        <div style="padding: 0 6%">
          <el-button color="#333" size="large" class="button" @click="jump"
            >了解详情</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";

const jump = () => {
  router.push({ path: "contact" });
};
</script>

<style lang="less" scoped>
.application-introduce-box {
  width: 100%;
  .headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 230px;
    width: 100%;
    justify-content: center;
    h2 {
      font-size: 34px;
      font-weight: bold;
      margin: 0;
    }
    p {
      font-size: 16px;
    }
  }
  .application-introduce-content {
    display: flex;
    width: 100%;
    height: 450px;
    background-color: #00a2e9;
    img {
      width: 50%;
      height: 100%;
    }

    .text-module {
      color: #fff;
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      // padding: 0 40px;
      font-weight: bold;
      h2 {
        font-size: 36px;
        margin-top: 0;
        margin-bottom: 0;
      }
      p {
        text-align: left;
      }
    }
  }

  .transition-application-img {
    background-image: url(../../assets/img/transition-industry-module-bg.png);
    width: 100%;
    height: 180px;
    margin-top: 160px;
  }

  button {
    border-radius: 0;
    font-size: 16px;
    padding: 0 16px;
    background-color: #333;
  }
}
</style>
<style>
button {
  font-weight: 600;
}
</style>

<template>
  <div class="technology-box">
    <div class="headline">
      <h2>HP Latex 技术优势</h2>
      <div class="driver"></div>
    </div>
    <div class="technology-list">
      <div
        v-for="item in technologyList"
        :key="item.id"
        class="technology-item"
      >
        <img :src="item.imgSrc" alt="" class="technology-item-img" />
        <h3>
          {{ item.title }}
        </h3>
        <p>{{ item.description }}</p>
      </div>
    </div>
    <div class="headline">
      <h2>HP Latex 技术利用水基分散聚合提升印件的耐用性</h2>
      <div class="driver"></div>
    </div>
    <div class="exert-list">
      <div v-for="item in exertList" :key="item.id" class="exert-item">
        <img :src="item.imgSrc" alt="" class="exert-item-img" />
        <h3>
          {{ item.title }}
        </h3>
        <p>{{ item.description }}</p>
      </div>
    </div>
    <div class="driver-space"></div>
    <div class="prohibit-list">
      <div v-for="item in prohibitList" :key="item.id" class="prohibit-item">
        <img :src="item.imgSrc" alt="" class="prohibit-item-img" />
        <h3>
          {{ item.title }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script setup>
let technologyList = [
  {
    id: 1,
    title: "应用多样性",
    description:
      "可使用Latex 墨水在各种承印物上打印耐刮擦的耐久打印件。通过高效率固化系统，印件一经打印，立刻干燥，可立即使用。",
    imgSrc: require("@/assets/img/various.svg"),
  },
  {
    id: 2,
    title: "优异的质量和生产力",
    description:
      "借助 HP Latex 打印头和分光光度计，可轻松获得所期待的色彩、品质与稳定。借助 HP Latex Optimizer 和光学介质推进传感器，尽享质量与效率兼备的打印新体验。",
    imgSrc: require("@/assets/img/production.svg"),
  },
  {
    id: 3,
    title: "践行端到端可持续发展理念",
    description:
      "运用高能效打印机、水性墨水和无异味印件实现打印可持续性，再也无需担心室内环境受到影响。此外，我们还提供简单的回收计划，旨在对符合条件的惠普耗材和打印材料进行回收再利用。",
    imgSrc: require("@/assets/img/sustainable.svg"),
  },
];

let exertList = [
  {
    id: 1,
    title: "应用阶段",
    description: "液体墨水连接料将乳胶聚合物和颜料颗粒带到打印介质表面。",
    imgSrc: require("@/assets/img/application-phase.png"),
  },
  {
    id: 2,
    title: "干燥阶段",
    description:
      "利用辐射热和强制气流蒸发掉大部分水，剩余物质则会形成连续的聚合物层。",
    imgSrc: require("@/assets/img/desiccation-phase.png"),
  },
  {
    id: 3,
    title: "固化阶段",
    description:
      "乳胶颗粒通过形成的耐用层来保护着色剂，从而形成随时可用的干燥印件。",
    imgSrc: require("@/assets/img/solid-phase.png"),
  },
];

let prohibitList = [
  {
    id: 1,
    title: "无需专用通风设备",
    imgSrc: require("@/assets/img/prohibit-1.png"),
  },
  {
    id: 2,
    title: "不含有害空气污染物",
    imgSrc: require("@/assets/img/prohibit-2.png"),
  },
  {
    id: 3,
    title: "无异味印件",
    imgSrc: require("@/assets/img/prohibit-3.png"),
  },
  {
    id: 4,
    title: "无反应性化学单体",
    imgSrc: require("@/assets/img/prohibit-4.png"),
  },
  {
    id: 5,
    title: "不产生臭氧",
    imgSrc: require("@/assets/img/prohibit-5.png"),
  },
];
</script>

<style lang="less" scoped>
.technology-box {
  margin-top: 20px;

  .headline {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2 {
      color: #333;
      font-size: 34px;
      margin-top: 100px;
    }

    .driver {
      width: 100px;
      height: 3.5px;
      margin-bottom: 30px;
      background-color: #00a2e9;
    }
  }

  .technology-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 80px;
    width: 100%;
    .technology-item {
      padding: 0 30px;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .technology-item-img {
        width: 120px;
        height: 120px;
        margin-bottom: 15px;
      }

      h3 {
        font-size: 34px;
        font-weight: bold;
        color: #000;
        margin: 0;
      }

      p {
        font-size: 16px;
        color: #333;
        text-align: left;
        font-weight: bold;
      }
    }
  }

  .exert-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 70px auto;
    width: 85%;
    .exert-item {
      display: flex;
      flex-direction: column;
      width: 23%;
      align-items: center;

      h3 {
        margin: 0;
        font-size: 28px;
      }

      .exert-item-img {
        width: 150px;
        height: 150px;
        margin-bottom: 25px;
      }
    }
  }

  .driver-space {
    height: 80px;
    margin: 0 auto;
    width: 85%;
    border-top: 2px solid #eaeaea;
  }
  .prohibit-list {
    display: flex;
    width: 85%;
    margin: 0 auto;
    justify-content: space-between;

    .prohibit-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 107px;
        height: 107px;
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
</style>

<!-- <template>
  <div class="swiper">
    <swiper
      :slidesPerView="1"
      :autoplay="{ delay: 2000 }"
      :navigation="true"
      :pagination="{ type: 'bullets', clickable: true }"
      :scrollbar="{ draggable: true }"
      :space-between="0"
      :modules="modules"
      @mouseenter="enter"
      @mouseleave="leave"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in swiperImgArray"
        :key="index"
        class="swiperItem"
        ><img :src="item.imgSrc" alt=""
      /></swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { toRaw, defineProps } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/less/navigation";
import "swiper/less/pagination";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
interface B {
  img: string;
}
let swiperImgArray = [
  {
    id: 1,
    imgSrc: require("@/assets/img/banner1.jpg"),
  },
  {
    id: 2,
    imgSrc: require("@/assets/img/banner2.jpg"),
  },
  {
    id: 3,
    imgSrc: require("@/assets/img/banner3.jpg"),
  },
];
let modules = [Autoplay, Navigation, Pagination, Scrollbar, A11y];
//定义swiperNew,目的获取非响应式swiper
let swiperNew: any;

//鼠标移入
const enter = () => {
  swiperNew.autoplay.stop();
};
//鼠标移出
const leave = () => {
  swiperNew.autoplay.start();
};
const onSwiper = (swiper: any) => {
  // console.log(swiper);

  swiperNew = toRaw(swiper); //拿到swiper对象再转换为非响应式
};
const onSlideChange = () => {
  // console.log("slide change");
};
</script>
<style lang="less" scoped>
.swiper {
  //   margin-top: 300px;
  width: 100%;
  height: 500px;

  img {
    width: 100%;
    height: 500px;
  }

  .swiperItem {
    // border: aqua solid 1px;
    height: 200px;
  }
}

:deep(.swiper-pagination)
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #00a2e9;
}

:deep(.swiper-pagination-bullet) {
  //修改分页器圆点大小
  width: 14px;
  height: 14px;
  background-color: #fff;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
:deep(.swiper-pagination-horizontal.swiper-pagination-bullets)
  .swiper-pagination-bullet {
  // 分页器远点之间的距离
  margin: 0 20px;
}

:deep(.swiper-button-prev),
:deep(.swiper-button-next) {
  color: #fff;
}
</style> -->
<template>
  <div
    class="hero-slider-box"
    :style="`background-image: url(${props.bgImage})`"
  >
    <div class="hero-slider-content">
      <p>{{ props.subtitle }}</p>
      <h1>{{ props.title }}</h1>
      <div>
        <p>{{ props.description }}</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, withDefaults } from "vue";

interface Props {
  bgImage: string;
  title: string;
  subtitle: string;
  description: string;
}

// 定义 props 并使用 withDefaults 设置默认值
const props = withDefaults(defineProps<Props>(), {
  bgImage: "",
  title: "",
  subtitle: "",
  description: "",
});
</script>

<style lang="less" scoped>
.hero-slider-box {
  // background-image: url(../../assets/img/home-hero.png);
  height: 532px;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  .hero-slider-content {
    max-width: 660px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 30px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    h1 {
      font-size: 40px;
      line-height: 42px;
    }
  }
}
</style>

<template>
  <div class="provide-service-box">
    <h2>尽享全新服务体验</h2>
    <p class="title-desc" style="text-align: center">
      利用数字技术和智能打印流程大幅提升效率、灵活性和盈利能力。在智能服务产品组合的帮助下，从容推动业务发展。
    </p>
    <div class="provide-service-content">
      <div v-for="item in list" :key="item.id" class="provide-service-item">
        <img :src="item.imgSrc" alt="" class="provide-service-img" />
        <h3>{{ item.title }}</h3>
        <div class="provide-service-line"></div>
        <p>{{ item.introduce }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
let list = [
  {
    id: 1,
    imgSrc: require("@/assets/img/service-running.png"),
    title: "尽可能延长正常运行时间",
    introduce:
      "借助智能工具，我们可以识别和远程诊断问题，让用户即时获得所需服务并大幅提高正常运行时间。提升对生产机组的掌控力，同时借此提升效率并为客户提供满意的服务。",
  },
  {
    id: 2,
    imgSrc: require("@/assets/img/service-printos.png"),
    title: "通过 HP PrintOS 提升运营水平",
    introduce:
      "随时随地掌控日常项目进度，并以数据为支撑做出决策。实时了解惠普打印机的工作状态，告别重复且耗时的设置流程。可自动备份并轻松恢复打印机及承印物配置。",
  },
  {
    id: 3,
    imgSrc: require("@/assets/img/service-choose.png"),
    title: "选择适合您的惠普支持服务",
    introduce:
      "惠普金牌服务和惠普服务合同可为覆盖范围内的打印机提供全面支持，助您尽可能延长设备正常运行时间并有效控制成本。不论是只有单台打印机还是有一整个机组，两款解决方案均可充分满足您的需求。",
  },
];
</script>

<style lang="less" scoped>
.provide-service-box {
  width: 100%;
  h2 {
    font-size: 33px;
    padding: 6vw 0.4042vw 0.6vw 0.4vw;
    text-align: center;
    font-weight: bold;
    color: #000;
  }
  .title-desc {
    color: #6e6e73;
    margin-bottom: 60px;
  }
  .provide-service-content {
    height: 600px;
    padding: 0 2%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .provide-service-item {
      display: flex;
      flex-direction: column;
      text-align: left;
      flex: 0 0 calc(33.333% - 2%); /* 每个元素占三分之一减去间距 */
      max-width: calc(33.333% - 2%); /* 确保最大宽度不超过三分之一减去间距 */
      margin: 1%;
      box-sizing: border-box;
      .provide-service-img {
        width: 100%;
        height: 285px;
        object-fit: cover;
        border-radius: 20px;
      }
      h3 {
        font-size: 28px;
        margin-bottom: 0;
      }
      p {
        width: 100%;
        box-sizing: border-box;
        height: 130px;
      }
      .provide-service-line {
        margin-top: 20px;
        margin-bottom: 10px;
        background-color: #00a2e9;
        width: 100px;
        height: 2.5px;
      }
    }
  }
}
</style>
